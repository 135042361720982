/* Base layout elements */
.countdown-container {
    background-color: rgb(251, 238, 231);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    text-align: center;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); 
    z-index: 999; 
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    font-size: 20px;
    margin: auto;
    padding: 20px;
    width: 100%;
    top: 0px;
    height: 80px;
  }
  
  .logo {
    height: 20px;
  }
  
  .page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    /* background: url('../assets/BG.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
  }
  
  .content {
    margin-top: 32px;
    font-size: 20px;
    color: #000;
    z-index: 1;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .main-content-container p{
    max-width: 680px;
  }
  
  .text-section {
    margin-left: 50px;
    max-width: 500px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .footer {
    position: absolute;
    bottom: 40px;
    display: none;
  }
  
  /* Media Queries for Layout */
  /* @media only screen and (min-width: 700px) and (max-width: 1100px) {
    .page-container {
      background: url(../assets/hands.png) right top no-repeat, url(../assets/flag.png) left bottom repeat;
      background-repeat: no-repeat;
      background-size: 50% 50%;
      height: 100%;
      width: 100%;
      margin-top: 80px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .page-container {
      background: url(../assets/hands.png) right top no-repeat, url(../assets/flag.png) left bottom repeat;
      background-repeat: no-repeat;
      background-size: 250px 30%;
      height: 100%;
      width: 100%;
      margin-top: 80px;
    }
  } */
  
  @media screen and (max-width: 1000px) {
    .main-content-container {
      font-size: medium;
    }
    
    .footer {
      display: block;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .content-container {
      flex-direction: column;
    }
    
    .text-section {
      text-align: center;
      margin-left: 0px;
    }
    
    .content {
      font-size: 3vw;
      max-width: 90%;
    }
  }