/* Animation and floating elements */
.floating-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .floating-ball {
    position: absolute;
    width: 20vw;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Smooth transform */
    will-change: transform; /* Optimize performance for smooth movement */
  }
  
  /* Position the balls initially */
  .ball-1 {
    top: -75px;
    left: 65%;
  }
  
  .ball-2 {
    top: 15%;
    left: 80%;
    width: 25vw;
  }
  
  .ball-3 {
    top: 60%;
    left: -50px;
    width: 25vw;
  }
  
  .ball-4 {
    top: 70%;
    left: 10%;
    width: 30vw;
  }
  
  /* Region buttons styling */
  #uk {
    background-color: rgb(255, 128, 65);
    -webkit-transform: perspective(800px) translateZ(-300px);
    background: url('../assets/Orange\ Button.svg') no-repeat;
    background-size: cover;
  }
  
  #chicago {
    background-color: rgb(0, 107, 84);
    -webkit-transform: perspective(800px) translateZ(-300px);
    background: url('../assets/Green\ Button.svg') no-repeat;
    background-size: cover;
    color: white;
  }
  
  #phoenix {
    background-color: rgb(156, 141, 214);
    -webkit-transform: perspective(800px) translateZ(-300px);
    background: url('../assets/Purple\ Button.svg') no-repeat;
    background-size: cover;
  }
  
  #brisbane {
    background-color: rgb(249, 179, 64);
    -webkit-transform: perspective(800px) translateZ(-300px);
    background: url('../assets/Yellow\ Button.svg') no-repeat;
    background-size: cover;
  }
  
  @media only screen and (min-width:678px) and (max-width: 1000px) {
    .ball-1 {
      top: -50px;
      left: 55vw;
    }
    
    .ball-2 {
      top: 100px;
      left: 70vw;
    }
    
    .ball-3 {
      top: 70%;
      left: -50px;
      width: 300px;
    }
    
    .ball-4 {
      top: 80%;
      left: 10%;
      width: 450px;
    }
  }

  @media only screen and (max-width: 678px) {
    .ball-1 {
      top: 50px;
      left: 55vw;
    }
    
    .ball-2 {
      top: 100px;
      left: 70vw;
    }
    
    .ball-3 {
      top: 78%;
      left: 0%;
    }
    
    .ball-4 {
      top: 80%;
      left: 75%;
    }
  }