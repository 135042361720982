@import url('https://styles.phmg.com/index.css');

@font-face {
  font-family: 'IvyOra Display';
  src: url('../assets/fonts/IvyOraDisplay-Regular.woff2') format('woff2'),
       url('../assets/fonts/IvyOraDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: 'IvyOra Display';
  src: url('../assets/fonts/IvyOraDisplay-Bold.woff2') format('woff2'),
       url('../assets/fonts/IvyOraDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Light */
@font-face {
  font-family: 'IvyOra Display';
  src: url('../assets/fonts/IvyOraDisplay-Light.woff2') format('woff2'),
       url('../assets/fonts/IvyOraDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: 'IvyOra Display';
  src: url('../assets/fonts/IvyOraDisplay-RegularItalic.woff2') format('woff2'),
       url('../assets/fonts/IvyOraDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Bold Italic */
@font-face {
  font-family: 'IvyOra Display';
  src: url('../assets/fonts/IvyOraDisplay-BoldItalic.woff2') format('woff2'),
       url('../assets/fonts/IvyOraDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Light Italic */
@font-face {
  font-family: 'IvyOra Display';
  src: url('../assets/fonts/IvyOraDisplay-LightItalic.woff2') format('woff2'),
       url('../assets/fonts/IvyOraDisplay-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'PHMG Mabry';
  src: url('../assets/fonts/PHMGMabry-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: 'PHMG Mabry';
  src: url('../assets/fonts/PHMGMabry-Bold.otf') format('otf');
  font-weight: bold;
  font-style: normal;
}

/* Light */
@font-face {
  font-family: 'PHMG Mabry';
  src: url('../assets/fonts/PHMGMabry-Light.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: 'PHMG Mabry';
  src: url('../assets/fonts/PHMGMabry-Italic.otf') format('otf');
  font-weight: normal;
  font-style: italic;
}

/* Bold Italic */
@font-face {
  font-family: 'PHMG Mabry';
  src: url('../assets/fonts/PHMGMabry-BoldItalic.otf') format('otf');
  font-weight: bold;
  font-style: italic;
}

/* Light Italic */
@font-face {
  font-family: 'PHMG Mabry';
  src: url('../assets/fonts/PHMGMabry-LightItalic.otf') format('otf');
  font-weight: 300;
  font-style: italic;
}

.ivory-font {
  font-family: 'IvyOra Display';
}