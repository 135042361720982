/* Button styles */
.join-stream-button {
  min-height: 64px;
  padding: 4px 32px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 999px;
  box-shadow: 0px 0px 0px 0.1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 20px;
font-weight: 300;
line-height: 20px;
word-wrap: break-word;
  text-align: center;
  transition: background-color 0.3s ease;
  margin: 48px auto;
}

.join-stream-button:hover {
  background-color: #333;
}

.location-button {
  min-height: 64px;
  padding: 4px 32px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 999px;
  box-shadow: 0px 0px 0px 0.1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 20px;
font-weight: 300;
line-height: 20px;
word-wrap: break-word;
  text-align: center;
  transition: background-color 0.3s ease;
  margin: 48px auto;
  width: 400px;
}

.location-button:hover {
  background-color: #333;
}

.button-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 32px;
  z-index: 1000;
}

.button-container {
  flex: 0 0 calc(50% - 6px); /* 50% width minus half the gap */
  display: block;
}

.location-button {
  width: 100%;
  padding: 16px;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* For mobile devices */
@media (max-width: 768px) {
  .button-container {
    flex: 0 0 100%;
  }
  .location-button{
    max-width: 90%;
    margin: auto;
  }
}


  
  .waiting-message {
    min-height: 64px;
    padding: 4px 32px;
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 999px;
    outline: 2px rgba(0, 0, 0, 0.20) solid    ;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 20px;
font-weight: 300;
line-height: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
    margin: 48px auto;
  }

  .workvivo-button {
    min-height: 64px;
    padding: 4px 32px;
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 999px;
    outline: 2px rgba(0, 0, 0, 1) solid    ;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 20px;
font-weight: 300;
line-height: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
    margin: 48px auto;
  }

  /* Responsive Styles */
@media (max-width: 768px) {
  .join-stream-button, .waiting-message, .workvivo-button {
      padding: 4px 24px;
      margin: 20px auto;
  }
}
  

  

  
  .rewatch-button {
    background-color: rgb(251, 238, 231);
    color: black;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rewatch-button:hover {
    background-color: rgb(251, 238, 231);
    filter: brightness(80%);
    transition: 0.3s;
  }
  

  
  @media only screen and (max-width: 800px) {
    .workvivo-button {
      margin: auto;
      padding: 10px 20px;
    }
  }