/* Countdown specific styles */
.countdown {
  display: flex;
  justify-content: center;
  font-size: 48px;
  z-index: 1;
  gap: 64px;
  margin: 48px auto;
}

.countdown-element {
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 250px; /* Increased width to give more space */
  position: relative; /* For absolute positioning of label */
}

.countdown-element:nth-child(even){
  margin-left: 50px;
}

@media only screen and (max-width: 700px) {
  .countdown-element:nth-child(even){
    margin-left: 0px;
  }
}

.countdown-value {
  font-size: 100px;
  font-family: IvyOra Display;
  font-weight: 400;
  line-height: 100px;
  word-wrap: break-word;
  text-align: right;
  min-width: 120px; /* Fixed width for the numbers */
  display: inline-block;
}

.countdown-label {
  font-size: 100px;
  font-family: IvyOra Display;
  font-weight: 400;
  font-style: italic;
  line-height: 100px;
  word-wrap: break-word;
  margin-left: 10px;
  display: inline-block;
}

@media only screen and (min-width:700px) and (max-width: 1000px) {
  .countdown-value, .countdown-label {
    font-size: 60px;
    min-width: 80px;

  }

  .countdown-element {
    width: auto;
  }
  .countdown {
    gap: 0px;
  }
}

@media only screen and (max-width: 700px) {
  .countdown-value, .countdown-label {
    font-size: 50px;
    min-width: 50px;
  }

  .countdown-element {
    width: auto;
  }
  .countdown {
    gap: 10px;
    margin: 20px auto;
  }
}

