/* Typography styles */
.event {
    font-size: 24px;
    font-family: IvyOra Display;
    font-weight: 500;
    word-wrap: break-word;
    z-index: 1000;
  }
  
  .event-time {
    font-size: 24px;
    font-family: IvyOra Display;
    font-style: italic;
    font-weight: 500;
    word-wrap: break-word;
  }
  
  .main-large-text {
    font-size: 80px;
    font-family: IvyOra Display;
    font-weight: 400;
    line-height: 91.20px;
    word-wrap: break-word;
  }
  
  .main-large-text-italic {
    font-size: 80px;
    font-family: IvyOra Display;
    font-weight: 400;
    font-style: italic;
    line-height: 91.20px;
    word-wrap: break-word;
  }
  
  .highlight {
    color: black;
  }
  
  .text-section h1 {
    font-size: 2.5vw;
    width: 350px;
  }
  
  .text-section p {
    margin: 20px 0;
  }
  
  @media only screen and (max-width: 1000px) {
    .text-section h1 {
      font-size: 5vw;
      width: auto;
    }
    .main-large-text{
      font-size: 48px;
font-family: IvyOra Display;
font-weight: 400;
line-height: 54.72px;
word-wrap: break-word
    }
    .main-large-text-italic{
      font-size: 48px;
font-family: IvyOra Display;
font-style: italic;
font-weight: 400;
line-height: 54.72px;
    }
  }