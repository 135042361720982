/* Video container styles */
.video-container {
    width: 100%;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: #fff;
  }
  .dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust opacity for effect */
    z-index: 999; /* Ensure it's on top of other elements */
  }
  .video {
    width: 100%;
    height: auto;
    max-height: 70vh;
    margin: auto;
    z-index: 1000;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media only screen and (max-width: 800px) {
    .video-container {
      margin: 0px auto 20px;
    }
  }